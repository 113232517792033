.auth_side_container {
  @include flexPosition(center, center);
  flex-direction: column;
  position: relative;
  width: 100%;
  background: linear-gradient($color-gradient-01, $color-gradient-02);
  overflow: hidden;

  @include mmdScreen {
    display: none;
  }

  .animated_text {
    position: absolute;
    left: -10%;
    top: 8%;
    width: 110%;
    transform: rotate(-10deg);

    @include llgScreen {
      left: -15%;
      top: -10%;
      width: 500%;
      transform: rotate(-15deg);
    }
  }

  .occupations {
    width: 100%;
    height: 50vh;
    background-image: url("../../assets/svg/occupations.svg");
    background-position: left;
    background-size: cover;
  }
}
