.privacy_page_container {
  .navbar_container {
    @include sectionPadding;
    background: linear-gradient($color-gradient-01, $color-gradient-02);
  }

  .privacy_policy {
    @include gridColumns(220px 1fr, 0px);

    @include mdScreen {
      @include gridColumns(80px 1fr, 0px);
    }

    @include smScreen {
      display: block;
    }

    .side {
      width: 100%;
      background: linear-gradient($color-gradient-01, $color-gradient-02);
      opacity: 0.01;

      @include smScreen {
        display: none;
      }
    }

    .content {
      @include sectionPadding;
      padding-top: 48px;
      padding-bottom: 72px;

      h1 {
        color: $color-gradient-01;
      }

      hr {
        margin-top: 6px;
        height: 5px;
        width: 52px;
        background: linear-gradient($color-gradient-01, $color-gradient-02);
        border: none;
      }

      p {
        font-size: 1em;
      }

      ul {
        padding-left: 28px;

        li {
          padding-bottom: 12px;
          list-style: disc;
          font-size: 1em;

          &:last-child {
            padding: 0;
          }
        }
      }
    }
  }
}
