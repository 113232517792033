.dropdown_container {
  position: relative;
  display: flex;
  margin: 0 0 0 auto;

  .drop {
    margin: 0;
    padding: 0;
    outline: none;
    width: fit-content;
    border: none;
    background: transparent;
  }

  &.show {
    .dropdown-menu {
      display: block;
    }
  }

  .dropdown-menu {
    inset: unset !important;
    top: -24px !important;
    left: auto !important;
    right: 0px !important;
    padding: 0;
    background: $color-white;
    border: 1px solid $color-grey-03;
    border-radius: 12px;
    box-shadow: 0px 4px 16px 0px #0000001f;
    transform: inherit !important;
    z-index: 1;
    display: none;

    @media screen and (max-width: 767px) {
      width: fit-content;
      width: -moz-fit-content;
    }

    .title {
      padding: 10px 12px;
      padding-bottom: 5px;
      font-size: 0.73em;
      color: rgba(132, 127, 133, 0.75);
      text-transform: uppercase;
    }

    .dropdown-item {
      padding: 12px 14px;
      font-size: 0.95em;
      font-weight: 500;
      min-width: 100px;
      text-wrap: nowrap;
      color: $color-black-01;
      background: $color-white;
      cursor: pointer;

      &:hover,
      &:focus {
        color: $color-black;
        background: $color-grey-00;
        outline: none;
      }
    }

    hr {
      margin-top: 8px;
      margin-bottom: 0;
    }
  }
}
