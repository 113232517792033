.auth_page_container {
  @include gridColumns(1fr 1fr, 0px);
  height: 100vh;

  @include llgScreen {
    @include gridColumns(220px 1fr, 0px);
  }

  @include mmdScreen {
    display: block;
  }

  .page_content {
    @include sectionPadding;
    @include flexPosition(space-between, center);
    flex-direction: column;
    position: relative;
    padding-top: 36px;
    padding-bottom: 36px;
    width: 100%;
    height: inherit;
    overflow: hidden;

    @include mmdScreen {
      padding-top: 24px;
      padding-bottom: 24px;
    }

    .top {
      width: 100%;
      height: 40px;

      .icon {
        @include circle(40px, 40px);
        @include flexPosition(center, center);
        margin: 0 0 0 auto;
        box-shadow: $box-shadow;
        cursor: pointer;
      }
    }

    .form_container {
      padding-right: 12px;
      max-width: 355px;
      width: 100%;
      overflow: scroll;

      &::-webkit-scrollbar {
        width: 5px;
        height: 0px;
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        width: 5px;
        height: 0px;
        background: $color-grey-03;
        border-radius: 3px;
      }

      @include mmdScreen {
        padding-top: 20px;
      }

      @include xsScreen {
        padding-right: 0;

        &::-webkit-scrollbar {
          width: 0;
        }

        &::-webkit-scrollbar-thumb {
          width: 0;
        }
      }

      .alread_text {
        font-size: 0.95em;
        font-weight: 500;
        text-align: right;

        a {
          font-weight: 600;
          text-decoration: underline;
          color: $color-gradient-01;
        }
      }

      .title {
        margin-top: 20px;
        font-size: 1.3em;
      }

      .sub_title {
        margin-top: 8px;
        margin-bottom: 20px;
        font-size: 0.95em;
      }

      .btn {
        margin-top: 20px;
        width: 100%;

        svg path {
          stroke: unset;
        }
      }

      .and_text {
        text-align: right;
        margin-top: 3px;
        font-size: 0.9em;
        font-weight: 500;
        color: $color-grey-04;

        span {
          font-weight: 600;
          color: $color-gradient-01;
        }
      }
    }
  }
}
