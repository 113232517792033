.topbar_container {
  @include flexPosition(space-between, center);
  width: 100%;
  height: 40px;

  .user {
    @include flexPosition(space-between, center);
    gap: 8px;
    padding: 10px 12px;
    max-width: 200px;
    background: $color-grey-01;
    border-radius: 12px;
    overflow: hidden;

    p {
      white-space: nowrap;
      overflow: hidden;
      font-size: 0.9em;
      font-weight: bold;
      width: 100%;
      background: linear-gradient($color-gradient-01, $color-gradient-02);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .icon {
    @include circle(40px, 40px);
    @include flexPosition(center, center);
    margin: 0 0 0 auto;
    box-shadow: $box-shadow;
    cursor: pointer;
  }
}
