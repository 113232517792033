.home_page_container {
  width: 100%;
  min-height: 100vh;

  .navbar_container {
    @include flexPosition(space-between, center);
    padding-top: 24px;
    padding-bottom: 24px;

    .logo_container img {
      width: 87px;
      height: auto;
    }

    .flex_end {
      @include flexPosition(flex-end, center);

      .language {
        @include flexPosition(flex-end, center);
        gap: 10px;
        margin-right: 28px;
        padding-right: 28px;
        height: 34px;
        border-right: 2px solid $color-grey-03;

        @include smScreen {
          display: none;
        }

        img {
          @include circle(20px, 20px);
        }

        p {
          font-weight: 500;
          color: $color-white;
        }
      }

      a {
        padding: 9px 28px;
        padding-bottom: 10px;
        font-weight: 500;
        color: $color-white;
        border-color: $color-white;
      }
    }
  }

  .hero_section {
    @include sectionPadding;
    min-height: 450px;
    background: linear-gradient($color-gradient-01, $color-gradient-02);

    .hero_content {
      margin: auto;
      margin-top: 48px;
      padding-bottom: 72px;
      text-align: center;

      .built {
        margin: auto;
        padding: 10px 36px;
        font-size: 0.85em;
        font-weight: bold;
        width: fit-content;
        color: $color-black;
        background: $color-blue;
        border-radius: 199px;

        @include smScreen {
          padding: 10px 18px;
          font-size: 0.8em;
        }
      }

      h1 {
        margin: auto;
        margin-top: 18px;
        font-size: 3em;
        font-weight: 500;
        line-height: 120%;
        color: $color-white;
        max-width: 700px;

        @include smScreen {
          font-size: 2em;
        }
      }

      .sub_title {
        margin: auto;
        margin-top: 18px;
        font-size: 1em;
        font-weight: 500;
        line-height: 160%;
        color: $color-white;
        max-width: 450px;
      }

      .btn_primary {
        margin: auto;
        margin-top: 24px;
        max-width: 247px;
      }

      .other_actions {
        @include flexPosition(center, center);
        gap: 24px;
        margin-top: 12px;

        a {
          font-size: 1em;
          font-weight: 500;
          color: $color-white;
          text-decoration: underline;
        }
      }
    }
  }

  .truly_yours {
    @include sectionPadding;
    margin-top: 80px;
    padding-bottom: 80px;
    background: $color-white;

    .d_flex {
      @include flexPosition(space-between, center);

      @include mdScreen {
        flex-direction: column;
        gap: 48px;

        .info {
          text-align: center;
          width: 100% !important;

          .btn_primary {
            margin: auto;
          }
        }
      }

      .info {
        width: 50%;

        h4 {
          font-size: 1.9em;
          font-weight: 600;
          color: $color-black;
        }

        p {
          margin-top: 18px;
          font-size: 1em;
          line-height: 185%;
          color: $color-black;
        }

        .btn_primary {
          margin-top: 18px;
          font-weight: 500;
          width: fit-content;
        }
      }

      img {
        margin: auto;
        width: 249px;
        height: 249px;
        border-radius: 60px;
      }
    }

    .brands {
      @include flexPosition(flex-start, center);
      gap: 28px;
      margin-top: 60px;
      width: 100%;

      img {
        margin: auto;
        width: auto;
        height: 40px;
      }
    }
  }

  .footer {
    @include sectionPadding;
    @include gridColumns(2fr 1fr 1fr 1fr 1fr, 24px);
    row-gap: 28px !important;
    position: relative;
    padding-top: 60px;
    padding-bottom: 150px;
    background: $color-black;
    overflow: hidden;

    @include lgScreen {
      @include gridColumns(2fr 1fr 1fr, 24px);
    }

    @include mmdScreen {
      @include gridColumns(2fr 1fr, 24px);
      padding-bottom: 100px;
    }

    @include smScreen {
      @include gridColumns(1fr 1fr, 24px);
    }

    .info {
      img {
        width: auto;
        height: 40px;
        object-fit: cover;
      }

      p {
        margin-top: 28px;
        font-size: 0.9em;
        color: $color-white;
      }

      .socials {
        @include flexPosition(flex-start, center);
        gap: 16px;
        margin-top: 36px;

        .icon svg {
          width: 28px;
          height: 28px;
          cursor: pointer;
        }
      }
    }

    .links {
      h6 {
        margin-bottom: 28px;
        font-size: 1em;
        font-weight: 500;
        color: $color-black-03;
      }

      a {
        display: block;
        margin-bottom: 18px;
        font-weight: 400;
        color: $color-white;
        width: fit-content;
      }
    }

    .animated_text {
      position: absolute;
      right: -20%;
      bottom: 10%;
      transform: rotate(-5deg);

      @include lgScreen {
        bottom: 2%;
        transform: rotate(-8deg);
      }
    }
  }
}
