.reserve_page_container {
  .my_domains {
    .header {
      @include flexPosition(space-between, center);
      gap: 24px;
      margin: auto;
      width: 100%;

      .info {
        h6 {
          font-size: 1.75em;
        }

        p {
          margin-top: 6px;
          font-size: 0.93em;
        }
      }

      .add {
        @include flexPosition(center, center);
        height: 70px;
        width: 140px;
        background: $color-grey-00;
        border-radius: 12px;
        cursor: pointer;

        &:hover {
          background: $color-grey-01;
        }

        .icon {
          @include flexPosition(center, center);
          @include circle(36px, 36px);
          background: $color-black;
          box-shadow: 0px 4px 16px 0px #0000001f;

          svg {
            color: $color-white;
          }
        }
      }
    }

    table {
      margin: auto;
      margin-top: 28px;
      width: 100%;

      thead tr td {
        padding: 6px 12px;
        font-size: 0.8em;
        font-weight: 500;
        border-bottom: 3px solid $color-grey-03;
      }

      tbody tr {
        &:nth-child(even) {
          background: $color-white-01;
        }

        td {
          padding: 16px 12px;

          .name {
            font-weight: 600;
            font-size: 0.95em;
            color: $color-black;
            text-wrap: nowrap;
          }

          .expires {
            font-size: 0.85em;
            color: $color-black-03;
            text-wrap: nowrap;
          }

          .payment {
            text-transform: capitalize;
          }

          .action {
            @include flexPosition(flex-start, center);
            gap: 2px;
            font-size: 0.95em;
            font-weight: 500;

            svg {
              color: $color-grey-04;
              transform: rotate(90deg);
            }
          }
        }
      }
    }

    .loader_img_container {
      @include flexPosition(center, center);
      padding: 48px 0px;

      svg {
        color: $color-black;
      }
    }

    .no_result {
      margin: 0 auto;
      padding: 48px 0px;
      text-align: center;
      max-width: 250px;

      img {
        width: 70px;
        height: auto;
      }

      p {
        margin-top: 12px;
        font-size: 0.93em;
        color: $color-black-03;
      }
    }
  }

  .register_domain {
    .go_back {
      margin-bottom: 36px;
      padding-bottom: 24px;
      border-bottom: 1px solid $color-grey-02;

      .icon {
        @include circle(40px, 40px);
        @include flexPosition(center, center);
        box-shadow: $box-shadow;
        cursor: pointer;

        svg {
          transform: rotate(-180deg);
        }
      }
    }

    .register_content {
      margin: auto;
      max-width: 343px;

      .title {
        font-size: 1.5em;
      }

      .sub_title {
        margin-top: 6px;
        font-size: 0.93em;
      }

      .verifying_minting {
        margin: 24px 0;
        padding: 18px 12px;
        background: $color-white-01;
        border: 1px dashed $color-grey-04;
        border-radius: 12px;

        .loader_img_container {
          @include flexPosition(center, center);
          flex-direction: column;

          svg {
            color: $color-black;
          }

          p {
            margin: 0;
            margin-top: 8px;
            font-size: 0.95em;
          }
        }
      }

      .d_flex {
        @include flexPosition(flex-start, flex-end);
        gap: 12px;
        margin: 24px 0;

        .form_group_container {
          margin: 0;
          width: 100%;

          .flex_input {
            padding: 0;

            .form-control {
              padding: 12px 16px !important;
              padding-bottom: 14px !important;
            }
          }
        }

        .btn {
          margin: 0;
          padding-left: 16px !important;
          padding-right: 16px !important;
          width: fit-content;

          svg {
            width: 20px;

            path {
              fill: $color-white;
            }
          }
        }
      }

      .domain_preview {
        @include flexPosition(flex-start, center);
        gap: 14px;
        padding: 16px;
        background: $color-grey-01;
        border-radius: 12px;

        .icon {
          @include circle(45px, 45px);
          @include flexPosition(center, center);
          background: $color-white;

          svg {
            color: $color-black;
          }
        }

        .info {
          h6 {
            font-size: 0.95em;
            font-weight: bold;
            color: $color-black;
          }

          p {
            margin-top: 3px;
            font-size: 0.9em;
            font-weight: 500;
            color: $color-black-03;
          }

          .loader_img_container {
            margin-top: 3px;

            svg {
              width: 18px;
              height: 18px;
              color: $color-black;
            }
          }
        }
      }

      .actions {
        margin-top: 24px;

        .btn {
          margin: 0;
          height: 54px;
          width: 100%;
        }
      }
    }
  }
}
